<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("markets_management.title") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
                <DatatableDropdown />
              <!--end::Dropdown-->
            </div>
          </div>
          <div class="card-body">
            <div v-if="isLoading">
              <div class="text-center">{{ $t("commons.data_loading") }}...</div>
            </div>
            <table
              class="table table-striped table-bordered collapsed"
              style="width: 100%"
              v-else
              ref="kt_datatable"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("markets_management.code") }}</th>
                  <th scope="col">{{ $t("markets_management.market_name") }}</th>
                  <th scope="col">{{ $t("markets_management.currency") }}</th>
                  <th scope="col">{{ $t("markets_management.volume") }}</th>
                  <th scope="col">{{ $t("markets_management.status") }}</th>
                  <th scope="col">{{ $t('commons.operations') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in markets"
                  :key="'bank-' + index"
                >
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.code | uppercase }}</td>
                  <td>{{ item.name }}</td>
                  <td></td>
                  <td></td>
                  <td>
                      <span class="badge" :class="[item.status === 10 && 'badge-success', item.status === 20 && 'badge-warning']">
                        {{ $t(`markets_management.status_types.${item.status}`) }}
                      </span>
                  </td>
                  <td>
                    <button v-if="checkPermission('crm.markets.edit')" type="button" class="btn btn-sm btn-clean btn-icon btn-icon-md" :title="$t('commons.edit')">
                        <i class="la la-pencil"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import $ from "jquery";
import DatatableDropdown from '@/components/datatable/Dropdown.vue'

export default {
  name: "MarketsList",
  data() {
    return { }
  },
  components: {
    Breadcrumb,
    DatatableDropdown
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.marketsManagement.isLoading,
      markets: (state) => state.marketsManagement.markets,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("general_management.title"),
        subtitle: [this.$t("markets_management.title")],
      };
    },
  },
  methods: {
    ...mapActions("marketsManagement", ["GET_MARKETS"]),
    checkPermission(permission) {
      return this.permissions?.some(item => String(item.name) === String(permission));
    },
    intializeDtTable() {
      const self = this;
      self.GET_MARKETS().then(() => {
        self.$nextTick(function () {
          var fileName = "Banks";
          $(self.$refs["kt_datatable"]).DataTable({
            responsive: true,
            pagingType: "full_numbers",
            dom: "Blfrtip",
            // read more: https://datatables.net/examples/basic_init/dom.html
            buttons: ["copy", "csv", "excel", "pdf", "print"].map((el) => ({
              extend: el,
              filename: fileName,
            })),
            pageLength: 10,
            initComplete: function () {
              $(".dt-buttons").hide();
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"],
            ],
            columnDefs: [
              { orderable: false, targets: [-1, -2] },
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
            ],
            language: {
              url: `/assets/languages/datatable/${this.lang}.json`,
            },
          });
          $("#printExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-print").trigger("click");
          });
          $("#copyExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-copy").trigger("click");
          });
          $("#excelExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-excel").trigger("click");
          });
          $("#csvExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-csv").trigger("click");
          });
          $("#pdfExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-pdf").trigger("click");
          });
        });
      });
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.markets.index")) {
      this.$router.push("/no-permission");
    }
    this.intializeDtTable()
  },
};
</script>
